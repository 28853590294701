// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { useState } from "react"
import { PageProps, Link, graphql } from "gatsby"
import useAuth from "../../hooks/useAuth";
import ReactMarkdown from "react-markdown";
import slugify from '@sindresorhus/slugify';

  
interface TripReportSummaryProps {
    title: string;
    presentOnTrip: Array<string>;
    cave: Object;
    date: Date;
    shortDescription: string;
    route: string;
    timeTaken: string;
    author: string;
}

const TripReportSummary: React.FC<TripReportSummaryProps> = ({title, presentOnTrip, cave, date, route, timeTaken, shortDescription, author}) => {
    const { isAuthenticated } = useAuth()
    return (
    <>
        <h2 style={{marginBottom:'0px'}}>{title}</h2>
        <h4 style={{marginBottom:'10px', marginTop:'0px'}}>{cave.name} - {date.toLocaleDateString('en-GB')}</h4>
        <p style={{lineClamp: 3, WebkitLineClamp: 3, overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitBoxOrient: "vertical"}}>
            {shortDescription}
        </p>
        <div>
            <a href={`/trip-reports/${slugify(cave.name)}/${date.toISOString().split('T')[0]}/${slugify(title)}`}>Read more...</a>
        </div>
        <small>Written by: {!!isAuthenticated ? author : "Login to see author"}</small>
    </>
  )
}

export default TripReportSummary
