import * as React from "react"
import { useState } from "react"
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar/navBar"
import TripReport from "../components/tripReport/tripReport"
import TripReportMenu from "../components/tripReportMenu/tripReportMenu"
import TripReportSummary from "../components/tripReport/TripReportSummary"
import slugify from "@sindresorhus/slugify"


function TripReportsPage() {
  const { allStrapiTripReport, allStrapiCave } = useStaticQuery(graphql`
  query {
    allStrapiTripReport(sort: {order: DESC, fields: date}) {
      nodes {
        title
        presentOnTrip
        author
        cave {
          name
          nickname
        }
        date
        shortDescription
      }
    }
    allStrapiCave(sort: {fields: name, order: ASC}) {
      nodes {
        id
        name
        nickname
        tripReports {
          title
          date
        }
      }
    }
  }
  `)
  const tripReports = allStrapiTripReport.nodes
  const caves = allStrapiCave.nodes

  const sideMenu = caves.map(cave => {

    const tripReports = cave.tripReports.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    }).map(trip => {
      return {
        title: `${new Date(trip.date).toLocaleDateString("en-GB")} - ${trip.title}`,
        url: `/trip-reports/${slugify(cave.name)}/${new Date(trip.date).toISOString().split('T')[0]}/${slugify(trip.title)}`
    }})
    return {
      section: `${cave.name} - ${cave.nickname}`,
      tripReports
    }
  })

  return (
    <Layout>
      <Seo title="Trip Reports" />
      <NavBar />
      <h1>Trip Reports</h1>
      <div>
        <div>
        {
          tripReports.map((trip, i) => (
            <div>
              <TripReportSummary
                    key={i}
                    title={trip.title}
                    presentOnTrip={trip.presentOnTrip}
                    cave={trip.cave}
                    date={new Date(trip.date)}
                    shortDescription={trip.shortDescription}
                    route={trip.route}
                    timeTaken={trip.timeTaken}
                    author={trip.author} />
                    <hr style={{marginTop: "20px", size:"4"}} ></hr>
              </div>
            ))
          }
        </div>
        <div>
          <TripReportMenu sections={sideMenu}/>
        </div>
      </div>
    </Layout>
  )
}

export default TripReportsPage
