// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { useState } from "react"
import { PageProps, Link, graphql } from "gatsby"

interface TripReportLink {
    title: string;
    url: string;
}

interface section {
    section: string;
    tripReports: TripReportLink[]
}

interface MenuSectionProps {
    section: section;
}

interface TripReportMenuProps {
    sections: section[];
}


const MenuSection: React.FC<MenuSectionProps> = (props) => {
    const { section, tripReports, } = props.section
    return (
        <React.Fragment>
            <li>{section}</li>
            <ol>
                {
                    tripReports.map((tripReport, i) => (
                        <li key={i}>
                            <a href={tripReport?.url}>{tripReport?.title}</a>
                        </li>
                    ))
                }
            </ol>
        </React.Fragment>
    )
}


const TripReportMenu: React.FC<TripReportMenuProps> = (props) => {
    return (
        <div>
            <ul>
                {
                    props.sections.map((section, i) => (
                        <MenuSection section={section} key={i}/>
                    ))
                }

            </ul>
        </div>
    )
};

export default TripReportMenu
